import React, {useState} from "react"
import Layout from "../../components/layout";
import {Col, Row, Container} from "react-bootstrap";
import ourPartnersStyle from "./our-partners.module.css";
import {graphql} from "gatsby";
import globalStyle from "../../styles/global.module.css";
import env from "../../config/env";
import Img from "gatsby-image";

const PartnersPage = ({data}) => {
  const resourceImages = {};
  data.allFile.edges.map(item => {
    resourceImages[item.node.name] = item.node;
  });

  let partnersData = data.site.siteMetadata.ourPartners;
  const [partners, setPartners] = useState(partnersData);

  const togglePartnerText = (partnerIndex) => {
    partners[partnerIndex].showAllContent = !partners[partnerIndex].showAllContent;
    setPartners([...partners]);
  }
  return (
    <Layout className="site-content"
            structuredData={{
              "@context": "https://schema.org",
              "@type": "ItemList",
              "numberOfItems": "2",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "image": [env.domain + resourceImages["busy-easy-cube-logo"].publicURL],
                  "url": "https://www.momsonmaternity.com/shop/our-partners/",
                  "name": "Busy Easy Toys",
                  "description": "This brand was created by a Mom of a very active toddler. She wanted to find a perfect way " +
                    "to interact her little girl without going to technology or the status-quo plastic toys. Every parent knows " +
                    "how hard it is to keep your baby/toddler really busy - no matter if it’s during lunch at a restaurant, on a " +
                    "road trip or long flight; or just when you simply need to work or get something done around the house.\n" +
                    "Busy Easy Cube designs interactive handmade toys that keep little ones occupied while developing their motor " +
                    "and sensory skills. Our cubes and cube attachments are perfect for the Mama on the go!",
                  "position": 1,
                },
                {
                  "@type": "ListItem",
                  "image": [env.domain + resourceImages["ushine-baby-logo"].publicURL],
                  "url": "https://www.momsonmaternity.com/shop/our-partners/",
                  "name": "USHINEBABY",
                  "description": "USHINEBABY is a small business run by Anna Tyson, a Ukrainian Mom, who lives in sunny San Diego." +
                    " Anna’s Mom used to make bonnets just like these by USHINEBABY when she was a little girl. " +
                    "Anna wanted to relive her happy childhood, summer-time memories and has done so by creating a brand with " +
                    "unique and creative hat designs.\n" +
                    "Check out the hand made bonnets for girls 6 months up to 5 years. ",
                  "position": 2,
                },
                {
                  "@type": "ListItem",
                  "image": [env.domain + resourceImages["fat-brain-toys-logo"].publicURL],
                  "url": "https://www.momsonmaternity.com/shop/our-partners/",
                  "name": "Fat Brain Toys®",
                  "description": "Fat Brain Toys® is a retailer and developer of unique specialty toys, games, and gifts. We stock over 7,000 " +
                    "high-quality products, most shipped the same day, including: Erector Sets, Science Projects, Games, Puzzles, Educational " +
                    "and much, much more." +
                    "For fourteen years, we've been carefully choosing the best toys from around the world in addition to acquiring the " +
                    "single largest selection of American-made toys found anywhere. But our unparalleled selection of quality toys is" +
                    "but one of many reasons that makes Fat Brain Toys unique: " +
                    "* We provide toll-free support by our award-winning customer care before AND after the sale " +
                    "* We stock nearly every product we sell from our own centrally-located warehouse " +
                    "* Most in-stock orders are shipped within 24 hours (if not faster!) " +
                    "* We ship orders worldwide including military APO's & FPO's" +
                    "Fat Brain Toys was named 2016 Best of the Web Hot 100 by Internet Retailer and we're proud to be in the Internet " +
                    "Retailer top 500 as well.",
                  "position": 3,
                },
                {
                  "@type": "ListItem",
                  "image": [env.domain + resourceImages["happiest-baby-logo"].publicURL],
                  "url": "https://www.momsonmaternity.com/shop/our-partners/",
                  "name": "Happiest Baby, INC.",
                  "description": "Happiest Baby, Inc. was founded by Dr. Harvey Karp, the world-renown pediatrician and best-selling author " +
                    "of Happiest Baby / Happiest Toddler on the Block. Dr. Karp is recognized with discovering that infants are born with " +
                    "an innate Calming Reflex, which is triggered by 5 steps that imitate the womb—Swaddling, Side-stomach position, " +
                    "Shushing, Swinging and Sucking—the so-called 5 S’s. Millions of parents, doctors and hospitals worldwide have " +
                    "turned to the 5 S’s to effectively calm fussing." +
                    "In 2016, Dr. Karp launched the SNOO Smart Sleeper, a responsive bassinet that solves the #1 parent complaint: exhaustion." +
                    "SNOO “hears” crying and automatically responds with gradually stronger white noise and rocking motion to soothe fussing. " +
                    "Additionally, SNOO’s built-in swaddle prevents risky rolling, making it the only sleep solution that keeps your " +
                    "baby safely on the back—all naps/all night—as recommended by the American Academy of Pediatrics.",
                  "position": 4,
                },
                {
                  "@type": "ListItem",
                  "image": [env.domain + resourceImages["care-logo"].publicURL],
                  "url": "https://www.momsonmaternity.com/shop/our-partners/",
                  "name": "Care.com",
                  "description": "Care.com is the largest and fastest-growing online marketplace that helps families find and manage care " +
                    "providers with more than 17 million members across 16 countries. Care.com is connecting families to local caregivers, " +
                    "including nannies, babysitters, afterschool sitters, housekeepers, senior care, dog walkers and more. Families " +
                    "can choose between 1-, 3- and 12-month premium memberships option and gain access to background check options, " +
                    "references, and reviews to make smarter hiring decisions.",
                  "position": 5,
                },
                {
                  "@type": "ListItem",
                  "image": [env.domain + resourceImages["jacadi-logo"].publicURL],
                  "url": "https://www.momsonmaternity.com/shop/our-partners/",
                  "name": "Jacadi",
                  "description": "Jacadi is a children’s fashion brand which transmits the timeless, authentic kid’s style from French " +
                    "heritage to worldwide youth." +
                    "Our collections includes newborn and children clothing, accessories, shoes and home products, specially designed " +
                    "to re-enchant and re-invent iconic French styles. Jacadi is currently active in 44 countries and operates 261 " +
                    "retail stores throughout the world.",
                  "position": 6,
                },
                {
                  "@type": "ListItem",
                  "image": [env.domain + resourceImages["dyper-logo"].publicURL],
                  "url": "https://www.momsonmaternity.com/shop/our-partners/",
                  "name": "Dyper",
                  "description": "JFor families with one kid or nine (or anywhere in between), Dyper® offers a diaper subscription service " +
                    "that is not just convenient and affordable, but also environmentally friendly. Our diapers are free of harmful " +
                    "irritants such as chlorine, latex, alcohol, perfumes, PVC, lotions, TBT or Phthalates and inks. Diapers from our " +
                    "website, dyper.com, are soft and absorbent and are made of naturally hypoallergenic & antimicrobial bamboo fibers " +
                    "and are biodegradable. Our convenient and intuitive subscription service uses Artificial Intelligence technology " +
                    "and predictive modeling to estimate the exact size and quantity of diapers you need. If we're off, our awesome " +
                    "partners can deliver your replacement diapers in as little as two hours! We offer an unconditional money back " +
                    "guarantee, FREE Shipping and prepaid returns.",
                  "position": 7,
                },
                {
                  "@type": "ListItem",
                  "image": [env.domain + resourceImages["gerber-childrenswear-logo"].publicURL],
                  "url": "https://www.momsonmaternity.com/shop/our-partners/",
                  "name": "Gerber Childrenswear LLC",
                  "description": "Gerber childrenswear LLC is a leading marketer of infant and toddler apparel and related products in the" +
                    " marketplace - offering all of the everyday, core layette apparel including Onesies® Brand one-piece underwear, " +
                    "sleepwear and accessories that mom needs for baby’s first years. We are the original maker and owner of the " +
                    "Onesies® Brand trademark* which has been a staple product in babies wardrobes throughout the U.S. for over 30 years.",
                  "position": 8,
                },
                {
                  "@type": "ListItem",
                  "image": [env.domain + resourceImages["chantecaille-logo"].publicURL],
                  "url": "https://www.momsonmaternity.com/shop/our-partners/",
                  "name": "Chantecaille",
                  "description": "For more than 20 years, Chantecaille has established a luxurious standard in the world of plant-powered " +
                    "botanical skincare and color cosmetics. From the coveted Bio Lifting Serum+ to the revolutionary algae-infused " +
                    "Future Skin foundation, the company has a ‘naturally beautiful’ ethos that has always been ahead of its time",
                  "position": 9,
                },
                {
                  "@type": "ListItem",
                  "image": [env.domain + resourceImages["chantecaille-logo"].publicURL],
                  "url": "https://www.momsonmaternity.com/shop/our-partners/",
                  "name": "Hickies",
                  "description": "Hickies is a footwear accessories company headquartered in Brooklyn, New York, producing a no-tie shoelace" +
                  "alternative for adults and kids and is sold in over 40 countries,The HICKIES® Lacing System is universal by design " +
                      "and is crafted to work with any shoe, as long as it has eyelets. Wear them in your sneakers, work shoes, boots, " +
                    "and even dress shoes. The lacing system also helps people with impaired fine motor skills, such as cerebral palsy " +
                    "and autism wear shoes that require lacing.",
                  "position": 10,
                },
                {
                  "@type": "ListItem",
                  "image": [env.domain + resourceImages["chantecaille-logo"].publicURL],
                  "url": "https://www.momsonmaternity.com/shop/our-partners/",
                  "name": "Shelving Inc.",
                  "description": "TheShelvingStore.com offers a wide selection of shelves, shelving units, wire shelving units, and much more " +
                    "to help you get and stay organized. Start organizing your kitchen, closet, garage, home office, bathroom or office." +
                    " Shop Now!",
                  "position": 11,
                },
              ]
            }}>

      <Container>
        <h2 className={globalStyle.entryTitle}>Meet Our Partners</h2>
        <Row style={{"marginTop": "2rem"}}>
          {
            partners.map((partner, index) => (
              <Col md={3} sm={4} xs={12} key={partner.name} style={{"marginTop": "2rem"}} >
                <div className={ourPartnersStyle.ourPartnersLogo}>
                  <Img fluid={resourceImages[partner.photo]['childImageSharp']['fluid']}></Img>
                </div>
                <p className={ourPartnersStyle.ourPartnersName}>{partner.name}</p>
                <div className={ourPartnersStyle.ourPartnersWebsiteLink}>
                  <a className={[globalStyle.redirectLink, ourPartnersStyle.ourPartnersWebsite].join(' ')} href={partner.website} target="_blank" rel="noopener noreferrer">Visit website</a>
                </div>
                <p className={ourPartnersStyle.ourPartnersDescription}>{partner.showAllContent ? partner.about : partner.about.replace(/#/g, '').substring(0, 200) + "..."}</p>
                <div className={ourPartnersStyle.seeMoreDetailsContent}>
                  {/*<Button className={ourPartnersStyle.seeMoreDetailsButton}*/}
                  {/*        // onClick={() => togglePartnerText(index)}*/}
                  {/*>*/}
                  <div className={ourPartnersStyle.seeMoreDetailsButton}>
                    <a href={partner.website} target="_blank" rel="noopener noreferrer">See more</a>
                  </div>

                    {/*{partner.showAllContent ? "See less" : "See more"} */}
                  {/*</Button>*/}
                </div>
              </Col>)
            )
          }
        </Row>
      </Container>
    </Layout>
  )
};


export const query = graphql`
  query {
    allFile(filter: {relativePath: {regex: "/market/our-partners\/.*/"}}) {
      edges {
        node {
          publicURL
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
          name,
        }
      }
    }
    site {
      siteMetadata {
        ourPartners {
          name
          about
          website
          photo
          showAllContent
        }    
      }
    }
  }
`;

export default PartnersPage
